import React, { useState } from 'react';
import axios from "axios";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [mobile_number, set_mobile_number] = useState("")
  const onChangeMobileNumber = (e) => {
    if(e.target.value.length<=10){
      set_mobile_number(e.target.value);
    }
    else{
      console.log(e.target.value)
    }
  }
  const AddTheQueries = async () => {
    let data = {
      "name": name,
      "email": email,
      "mobileNumber": mobile_number,
      "subject": subject,
      "message": message,
      "status": "Pending",
      "createdAt":
        new Date().getUTCFullYear() +
        "-" +
        (Number(new Date().getUTCMonth()) + 1) +
        "-" +
        new Date().getUTCDate() +
        "-" +
        new Date().getUTCHours() +
        "-" +
        new Date().getUTCMinutes() +
        "-" +
        new Date().getUTCSeconds(),
      "updatedAt":
        new Date().getUTCFullYear() +
        "-" +
        (Number(new Date().getUTCMonth()) + 1) +
        "-" +
        new Date().getUTCDate() +
        "-" +
        new Date().getUTCHours() +
        "-" +
        new Date().getUTCMinutes() +
        "-" +
        new Date().getUTCSeconds(),
    };
    try {
      const response = await axios.post(`https://node-service-app-ifox3xnafa-el.a.run.app/websiteQueries`, data);
      if (response.status == 200) {
        console.log(response);
        alert("Successfully send Queries")
        setEmail("");
        setName("");
        set_mobile_number("");
        setSubject("");
        setMessage("");

      }
    } catch (error) {
      console.log(error.message);

    }
  };
  return (
    <div className="w-full bg-[#04103D] py-5">
      <div className="xl:max-w-screen-xl w-full mx-auto flex flex-col gap-5 p-3">
        <div className="text-white text-4xl font-semibold flex flex-col items-center justify-center py-10 w-full">
          <>Contact Information
          </>
        </div>
        <div className="grid xl:grid-cols-2 gap-5 w-full">
          <input type='text' value={name} onChange={(e) => setName(e.target.value)}  placeholder='Enter The Name' />
          <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter The Email' />
          <input type='number' maxLength={10}  value={mobile_number} onChange={(e) => onChangeMobileNumber(e)} placeholder='Enter The Phone' />
          <input type='text' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Enter The Subject' />
        </div>
        <div className="w-full">
          <textarea type='text' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Enter The Message' />
        </div>
        <div className="w-full">
          <button onClick={()=>AddTheQueries()}  className="border-2 px-3 rounded-full bg-white text-[#04103D] inline-block py-2" >Save The Profile</button>
        </div>
      </div>
    </div>
  )
}

export default Contact;
