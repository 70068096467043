
import axios from "axios";
import React, { useState } from 'react';
import KalamHomeBanner from '../Images/KalamHeaderBanner.png';
import ChiefGuest1 from '../Images/KALIYAMURTHY.png'
import ChiefGuest2 from '../Images/NITHYAANANDAM.png'
import Entertainer1 from '../Images/Entertainer1.png';
import Entertainer2 from '../Images/Entertainer2.png';
import Entertainer3 from '../Images/Entertainer3.png';
import Sponser1 from '../Images/Sponsership1.png'
import Sponser2 from '../Images/Sponsership2.png'
import Sponser3 from '../Images/Sponsership3.png'
import Sponser4 from '../Images/Sponsership4.png'
import Sponser5 from '../Images/Sponsership5.png'

function Kalam() {
  const [open_qr, set_open_qr] = useState(false);
  const [name, set_name] = useState("");
  const onChangeName = (e) => {
    set_name(e.target.value);
  }
  const [mobile_number, set_mobile_number] = useState("")
  const onChangeMobileNumber = (e) => {
    set_mobile_number(e.target.value);
  }
  const OnRegistrationClicked = () => {
    if (name.length > 0 && mobile_number.length === 10) {
      axios.post(`https://node-service-app-ifox3xnafa-el.a.run.app/add_kalam_registration`,
        {
          name: name,
          mobile_number: mobile_number
        }).then((response) => {
          if (response.status === 200) {
            set_open_qr(true);
          }
          else {
            alert(response.message);
          }
        }).catch((error) => {
          alert(error.message);
        })
    }
    else {
      alert('Fields are missing or invalid data!!')
    }
  }
  const onCloseButtonClicked = () => {
    set_name("");
    set_mobile_number("");
    set_open_qr(false);
  }
  let style = `flex flex-col gap-5 xl:max-w-[600px] items-center justify-center w-full h-full relative`;
  const Entertainer = [
    {
      img: Entertainer1,
      name: "Kiki Shantantu",
      role: "Anchor"
    },
    {
      img: Entertainer2,
      name: "TSK",
      role: "Entertainer"
    },
    {
      img: Entertainer3,
      name: "Azar",
      role: "Entertainer"
    },
  ]
  return (
    <div className="w-full bg-[#04103D]">
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <img src={KalamHomeBanner} />
          </div>
          <div className="flex flex-col gap-5 xl:max-w-[600px] w-full h-full relative">
            <div className="text-[#104CBA] text-3xl font-bold inline-block"> Bookings for <span className="text-[#F58C2B]">KALAM</span></div>
            <div className="flex flex-col gap-3">
              <div className="text-white">Name* </div>
              <input type='text' value={name} onChange={(e) => onChangeName(e.target.value)} placeholder='Enter The Name' />
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-white">Mobile Number* </div>
              <input type='text' value={mobile_number} onChange={(e) => onChangeMobileNumber(e.target.value)} placeholder='Mobile Number' />
            </div>
            <div className="flex flex-col gap-5">
              <button onClick={() => OnRegistrationClicked()} className="bg-[#00AEC9] text-white p-2 rounded">SUBMIT</button>
            </div>
          </div>
        </div>

      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="text-white flex text-5xl flex-col items-center justify-center w-full"> Chief Guest  </div>
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <img className="w-[90%] h-[400px] object-contain" src={ChiefGuest1} />
            <div className="text-[#FFAD00] text-xl font-bold inline-block">Dr. A KALIYAMURTHY</div>
            <div className="text-white text-2xl">
              Former Superintendent of Police
            </div>
          </div>
          <div className={style}>
            <img className="w-[90%] h-[400px] object-contain" src={ChiefGuest2} />
            <div className="text-[#FFAD00] text-xl font-bold inline-block">PROF.C.NITHYAANANDAM</div>
            <div className="text-white text-2xl">
              Founder & Director of Thavam Research Foundation
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="text-white flex text-5xl flex-col items-center justify-center w-full"> Our Celebrity Guest  </div>
        <div className="grid lg:grid-cols-3 grid-cols-2 items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          {Entertainer.map((item, index) =>
            <div className={style}>
              <img className="w-full h-[300px] object-contain" src={item.img} />
              <div className="text-[#FFAD00] text-2xl font-bold inline-block">{item.name}</div>
              <div className="text-white text-xl">
                {item.role}
              </div>
            </div>)}

        </div>
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <img className="w-full h-full object-contain" src={Sponser1} />
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <img className="w-full h-full object-contain" src={Sponser2} />
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <img className="w-full h-full object-contain" src={Sponser3} />
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <img className="w-full h-full object-contain" src={Sponser4} />
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <img className="w-full h-full object-contain" src={Sponser5} />
      </div>
    </div>
  );
}

export default Kalam;
