import React from "react";

const AntiDiscriminationPolicy = () => {

  return (
    <div className="w-full bg-[#04103D] text-white h-screen">
      <div className="xl:max-w-screen-xl w-full mx-auto p-3">
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5" >Anti Discrimination Policy</h1>
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5">Last Updated 04 July 2024</h1>
        <div className="flex flex-col gap-3">
          <div>
            PLANEXT4U ALL SOLUTIONS INDIA PVT seeks to empower millions of service professionals across the India to deliver safe, reliable and high quality services at home. PLANEXT4U ALL SOLUTIONS INDIA PVT therefore does not tolerate, and prohibits discrimination against customers or service providers based on religion, caste, race, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other characteristic that may protected under applicable laws.
            <p />Such discrimination includes, but is not limited to, refusing to provide or accept services based on any of these characteristics.
            <p />Any customer or service partner found to have violated this prohibition will lose access to the PLANEXT4U ALL SOLUTIONS INDIA PVT platform.
          </div>
        </div>
      </div>
    </div>
  );
};
export default AntiDiscriminationPolicy;