import React from "react";




const ShippingPolicy = () => {
  return (
    <div className="w-full bg-[#04103D] text-white">
      <div className="xl:max-w-screen-xl w-full mx-auto p-3">
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5" >SHIPPING & DELIVERY POLICY</h1>
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5">Last Updated July 31,2024</h1>
        <div className="flex flex-col gap-4">
          <p>Please Carefully review our shipping & Delivery Policy When Purchasing our products.This policy will apply to any order you place with us.</p>
          <h2>WHAT ARE MY SHIPPING & DELIVERY OPTIONS?</h2>
          <p>We are various shipping options.In some cases a third-party supplier may be manageing our inventory and will be responsible for shipping your products</p>
          <h4>Shipping Fees</h4>
          <p>We offer shipping at the following rates</p>
          <div className='flex flex-row items-center gap-10'>
            <p>Shipping Method</p>
            <p>Postal/Parcel 15 days</p>
          </div>
          <div className='flex flex-row items-center gap-5'>
            <p>Shipping Free</p>
          </div>
          <p>All times and dates given for delivery of the products are given in good faith but are estimates only </p>
          <h4>DO YOU DELIVER INTERNATIONALLY?</h4>
          <p>We do not offer international shipping</p>
          <h4>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h4>
          <p>if you have any further questions of comments,you may contact us by</p>
          <a href='https://mail.google.com'>
            <p>Email : support@planext4u.com</p>
          </a>
        </div>
      </div>
    </div>
  );
};
export default ShippingPolicy;