import React from "react";


const RefundAndCancellationPolicy = () => {

  return (
    <div className="w-full bg-[#04103D] text-white">
      <div className="xl:max-w-screen-xl w-full mx-auto p-3">
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5" >Payment Terms and Conditions</h1>
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5">Last Updated 04 July 2024</h1>
        <div className="flex flex-col gap-3">
          <br />These Payment Terms and Conditions govern the use of the payment services provided by
          P4U. By using our payment services, you agree to be bound by these Payment Terms and
          Conditions.
          <br />Payment Methods
          <br />1. P4U accepts the following payment methods:
          <br />● Credit cards (Visa, Mastercard, American Express)
          <br />● Debit cards (Visa, Mastercard)
          <br />● Net banking
          <br />● UPI
          <br />● Wallets
          <br />Payment Processing
          <br />2. Payment processing begins as soon as you submit your payment information. Your
          payment will be authorized and captured once the transaction is complete.
          <br />Refunds and Cancellations
          <br />3. Refunds and cancellations are subject to the terms and conditions of the service or
          product being purchased. Please refer to the specific product or service page for
          refund and cancellation policies.
          <br />Chargebacks
          <br />4. If you dispute a charge on your credit card, P4U may dispute the chargeback with the
          credit card issuer. Chargebacks may result in additional fees and charges.
          Payment Disputes
          <br />5. If you have any disputes regarding your payment, please contact us at
          support@p4u.com. We will work with you to resolve any issues as soon as possible.
          <br />Late Payments
          <br />6. If your payment is not received by the due date, P4U reserves the right to suspend or
          terminate your account or withhold any services or products until payment is
          received. Late payments may be subject to additional fees and charges.
          <br />Taxes
          <br />7. You are responsible for paying any applicable taxes on your purchases. Taxes may
          vary depending on your location and the products or services being purchased.
          Fees and Charges
          <br />8. P4U reserves the right to charge fees and charges for the use of our payment
          services. Fees and charges may vary depending on the payment method used and
          the products or services being purchased. We will provide you with notice of any fees
          and charges before processing your payment.
          <br />Security
          <br />9. P4U takes reasonable measures to ensure the security of your payment information.
          We use industry-standard encryption and secure payment gateways to protect your
          payment information.
          <br />Changes to Payment Terms and Conditions
          <br />10. P4U reserves the right to update these Payment Terms and Conditions at any time.
          We will notify you of any material changes by posting the updated Payment Terms
          and Conditions on our website or by other means. Your continued use of our
          payment services after any changes indicates your acceptance of the updated
          <br />Payment Terms and Conditions.
          <br />Governing Law and Dispute Resolution
          <br />11. These Payment Terms and Conditions shall be governed by and construed in
          accordance with the laws of [Insert the governing law of your country]. Any disputes
          arising out of or in connection with these Payment Terms and Conditions shall be
          resolved by [Insert the dispute resolution method, such as arbitration or mediation].
          If you have any questions or concerns about these Payment Terms and Conditions, please
          contact us at support@p4u.com.
        </div>
      </div>
    </div>

  );
};
export default RefundAndCancellationPolicy
