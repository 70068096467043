import React from "react";




const PrivacyPolicy = () => {
  return (
    <div className="w-full bg-[#04103D] text-white">
      <div className="xl:max-w-screen-xl w-full mx-auto p-3">
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5" >Privacy Policy</h1>
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5">Last Updated 04 July 2024</h1>
        <div>
          <h2>Welcome to Planext4u  privacy policy (“Privacy Policy” or “Policy”).</h2>

          PLANEXT4U ALL SOLUTIONS INDIA PRIVATE LIMITED to safeguarding the privacy of our website and/or mobile application visitors, this policy sets out how we will treat your personal information.
          <h1>WHAT DO WE COLLECT?</h1>
          We may collect, store and use the following kinds of personal data upon user consent:
          <br /><br />
          <h1>Registration and Profile Information:</h1>
          When you sign up for an account or use our services, we will ask you for the following information:
          <br />a ) First and Last Name
          <br /> b ) Location
          <br /> c ) Profile Photo
          <br /> d ) Mobile Number
          <br />e ) Any other relevant information as required from time to time collected upon user consent.
          <br />Sensitive information is stored in encrypted format.
          <br /><br />
          <h1>Communications:</h1>
          If you contact us directly, we may receive additional information about you. For example, when you contact us for support, we will receive your name, email address, phone number and contents of your support issue along with any other information you chose to provide.
          <br /><br />
          <h1>Device Information:</h1>
          We receive information about the device and software you use to access our Services, including IP address, type of Web Browser, Operating System Version, Phone Carrier and Manufacturer, application installations, device identifiers and push notification tokens.
          <br /><br />
          <h1>Usage Information:</h1>
          To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with them, like the pages or other content you view. The information you upload, the length of time you spend on a page, objects such as hyperlinks you click on, and the dates and times of your visits.
          <br /><br />
          <h1>Contacts Information</h1>
          The users' contact information is collected only for the following purpose - to enable users to communicate and share content with their friends (who are in contact only). Our app functions in a way to build friends network, who are in the contacts.
          This will provide a good user experience for the user, where they are easily able to connect with their contacts. The above data is collected only after every individual user's approval. Without the user's consent, our app won't collect any contact data from the user.
          <br /><br />
          <h1>Post Upload:</h1>
          When you use our services, you trust us with your information to post photos While uploading the post, we are collecting post related images & text. Users can change access to private or public posts.
          <br /><br />
          <h1>HOW DO WE USE YOUR PERSONAL DATA?</h1>
          Personal Data submitted on our Platform will be used for the purposes specified in this Privacy Policy or in relevant parts of our Website/ Mobile Application.
          <br /><br />
          <h1>We may use your Personal Information:</h1>
          - To provide, maintain, improve and enhance our Services;
          <br />- To understand and analyse how you use our Services and develop new products, services, features and functionality;
          <br /> - To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;
          <br /> - To generate de-identified or aggregated data for our business purposes
          <br /> - To send you to push notifications and text & image messages
          <br />- To find and prevent fraud, and respond to trust and safety issues that may arise
          <br />- For compliance purposes, including enforcing our Terms of Service or other legal rights, as may be required by applicable laws and regulations, or as requested by any judicial process or governmental agency, and
          <br />- For other purposes for which we provide specific notice at the time the information is collected.
          <h1>HOW DO WE SHARE THE INFORMATION WE COLLECT?</h1>
          We do not share or otherwise disclose the information we collect from or about you except as described below or otherwise disclosed to you at the time of the collection:
          <br /><br />
          <h1>User Content</h1>
          Our services are Services & social networking services in which you can view and share content. Your name, user name and other profile information will be viewable by other users. The content you share on the services will be displayed on the services and viewable by other users to whom you have given access to view such content. Our platform will not be responsible for the other users' usage of your content, so you should carefully consider whether and what to share and how you identify yourself on the platform.
          P4U_user is a services & social network app, where users can post and share their text, images, with their friends & followers through the platform's content feed. Our app enables the user to build their friends & followers' networks and make them view others' content & share the user's own content (text, images), only after their consent and approval. In this way, the app functions as a social networking platform for the end-users. Without the user's consent, our app won't collect any content data from the users. This content data will be stored with us, and we assure you that we won't share this data with any other 3rd parties
          <br /><br />
          <h1> Analytics Partners :</h1>
          We may use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps and online resources.
          As required by Law and Similar Disclosures-
          We may access, preserve and disclose your information if we believe doing so is required or appropriate to :
          <br />a ) Comply with law enforcement requests and legal processes, such as a Court Order or subpoena;
          <br /> b ) Respond to your requests; or
          <br /> c ) Protect your, our, or others’ rights, property, or safety.
          <br />For the avoidance of doubt, the disclosure of your information may occur if you send any objectionable content on or through our services.
          <br /> Merger, Sale or Other Asset Transfer-
          <br />We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets.
          <br /><br />
          <h1> Consent</h1>
          We may also disclose your information with your permission.
          <br /><br />
          <h1> WHERE WE STORE YOUR PERSONAL INFORMATION</h1>
          We store your data with Google Cloud Platform Services, Google Firebase on its servers located in India. The Web Service implements the requisite Security measures to protect the loss, misuse and alteration of the information, details of which are available at Google Cloud Platform Services, Google Firebase.
          <br /><br />
          <h1>YOU’RE CHOICES</h1>
          <h1>Sharing Preferences</h1>
          We provide you with settings to allow you to set your sharing preferences for content you share on our services. Certain information may always be publicly available to others, and other information is publicly viewable, you can adjust the settings in your account.
          <br /><br />
          <h1>Additional Information</h1>
          Additional information regarding privacy settings can be found here: support@planext4u.com
          <br /><br />
          <h1>Do Not Track</h1>
          There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals. If you choose not to provide us with the information we collect, some features of our services may not work as intended.
          <br /><br />
          <h1>SECURITY</h1>
          We make reasonable efforts to protect your information by using physical and electronic safeguards designed to improve the security of the information we maintain. However, as no electronic transmission or storage of information can be entirely secure, we can make no guarantees as to the security or privacy of your information.
          <br /><br />
          <h1>THIRD PARTIES</h1>
          Our Services may contain links to other websites, apps, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties. We encourage you to read their privacy policies before providing any information to them.
          <br /><br />
          <h1>CHILDREN’S PRIVACY</h1>
          We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services are directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, you may alert us at support@planext4u.com
          <br /><br />
          <h1>INTERNATIONAL VISITORS</h1>
          Our Services are hosted in the United States and intended for visitors located within the United States. If you choose to use our Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Services. By providing any information, including personal information, on or to our Services, you consent to such transfer, storage, and processing.
          <br /><br />
          <h1>UPDATE YOUR INFORMATION OR POSE A QUESTION</h1>
          You can update your account and profile information through your profile settings. If you have questions about your privacy on our Services or this privacy policy, please contact us at support@planext4u.com
          <br /><br />
          <h1>CHANGES TO THIS PRIVACY POLICY</h1>
          We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through our Services, we will attempt to notify you through our Services, by email, or other means.
          <br /><br />
          <h1>GRIEVANCE POLICY</h1>
          We at P4U-user, always thrive to deliver the best values to our users and give the best experience under all circumstances. To offer this seamlessly, we've created this Grievance policy to solve all our customer's grievance-related problems in a very efficient way. We always try our best way to provide a seamless transaction experience on the P4U-user app. In some rare circumstances, due to random human-related or technical errors, chances are there for issues to happen for our users. To address this kind of issue, our grievance policy will be surely helpful for our end users.
          <br /> 1. BACKGROUND AND KEY INFORMATION
          <h1> How this Policy applies:</h1>
          This Policy applies to individuals who access or use the Services or otherwise avail the Professional Services. For the avoidance of doubt, references to “you” across this Policy are to an end user that uses the Platform.
          By using the Platform, you consent to the collection, storage, usage, and disclosure of your personal data, as described in and collected by us in accordance with this Policy.
          <h1> DELETION OF ACCOUNT AND PERSONAL DATA</h1>
          Notwithstanding anything contained in the Terms, you may delete your account as well as your personal data stored with planext4u Company by sending an email to privacy@planext4u.com. Planext4u  Company may take up to 7 (seven) working days to process your request. Once your account is deleted, you will lose access to all Services. For avoidance of doubt, it is hereby clarified that all data with respect to transactions performed by you on the Platform will be retained in accordance with applicable law.
          <h1>Grievance Officers</h1>
          Name: Ms. Priyanka Vijay;
          <br /> Designation: Vice President, Engineering
          <br /> Email: privacy@planext4u.com
          <br /> Name: Mr. vijay
          <br /> Designation: Head of Legal
          <br /> Email: privacy@planext4u.com
          <br /><br />
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;