import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";
import p4u_v1 from "../Assets/p4u_v1.mp4";
import Planextlogo from "../Images/planextlogo.png";
import bm01 from "../Assets/bm01.png";
import bm02 from "../Assets/bm02.png";
import bm03 from "../Assets/bm03.png";
import bm04 from "../Assets/bm04.png";
import bm05 from "../Assets/bm05.png";

function Community() {
  // const settings = {
  //   className: "center",
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: "100px",
  //   slidesToShow: 3,
  //   speed: 100,
  //   arrows: false
  // };
  const slides = [bm01, bm02, bm03, bm04, bm05];


  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    focusOnSelect: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          initialSlide: 2,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          infinite: true,
          centerMode: true,
        },
      },
    ],

  };
  return (
    <div className="w-full bg-[#04103D] flex  flex-col gap-5 py-5">
      <div className='flex flex-col items-center justify-center gap-5'>
        <div className="text-white flex text-3xl flex-col gap-5 items-center justify-center w-full"> WELCOME TO P4U FAMILY  </div>
        <img className="w-20 h-20 object-contain" src={Planextlogo} />
      </div>
      <div className="slider-container w-full items-center justify-center">
        <Slider {...settings}>
          {slides.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
              <img className='rounded-lg h-[500px] px-5 object-contain' src={img} alt={img} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16" />
      <div className="text-white flex text-3xl flex-col items-center justify-center w-full"> What is P4U community?
      </div>

      <div className="xl:max-w-screen-xl w-full mx-auto flex flex-col items-center justify-center  p-3">
        <ReactPlayer url={p4u_v1} controls={true} playing={true} loop={true} />

      </div>
    </div>


  )
}

export default Community;
