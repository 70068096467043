import React from "react";


const TermsAndConditions = () => {

  return (
    <div className="w-full bg-[#04103D] text-white">
      <div className="xl:max-w-screen-xl w-full mx-auto p-3">
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5" >Terms And Conditions</h1>
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5">Last Updated 04 July 2024</h1>
        <div className="flex flex-col gap-3">


          <p /> <h1>  These terms and conditions (“Terms”) govern the use of services made available mobile application ("Platform") made available by PLANEXT4U ALL SOLUTIONS INDIA PRIVATE LIMITED</h1>
          The Terms constitute a binding and enforceable legal contract between PLANEXT4U ALL SOLUTIONS INDIA PRIVATE LIMITED (a company incorporated under the Companies Act, 2020 with its registered address at SF No.250/2, J J Nagar, NN Palayam Pattanam, Coimbatore, 641016, Tamil Nadu. and its affiliates (“UC”, “we”, “us”, or “our”), and you, a user of the Services, or any legal entity that books Pro Services (defined below) on behalf of end-users (“you” or “Customer”). By using the Services, you represent and warrant that you have full legal capacity and authority to agree to and bind yourself to these Terms. If you represent any other person, you confirm and represent that you have the necessary power and authority to bind such person to these Terms.
          <p />    By using the Services, you agree that you have read, understood, and are bound by, these Terms, as amended from time to time, and that you will comply with the requirements listed here. These Terms expressly supersede any prior written agreements with you. If you do not agree to these Terms, or comply with the requirements listed here, please do not use the Services.
          <p />   These Terms and Conditions govern your use of our Platform.
          <p />   Our Privacy Policy also governs your use of our Platform and explains how we collect, safeguard and disclose information that results from your use of our web pages.
          <p />   Your agreement with us includes these Terms and Conditions as well as our Privacy Policy (“Agreements”). You acknowledge that you have read and understood the Agreements, and agree to be bound of them.
          <p />    If you do not agree with (or cannot comply with) the Agreements, then you may not use the Platform, but please let us know by emailing at support@planext4u.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Platform.
          <h1>  COMMUNICATIONS</h1>
          By using our Platform, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at support@planext4u.com
          <p />  CONTESTS, SWEEPSTAKES AND PROMOTIONS
          <p />  Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through Platform may be governed by rules that are separate from these Terms and Conditions. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms and Conditions, Promotion rules will apply.
          <h1> CONTENT</h1>
          Our Platform allows you to post, link, store, share and otherwise make available certain information, text, graphics or other material ("Content"). You are responsible for Content that you post on or through the Platform, including its legality, reliability, and appropriateness.
          <p />   By posting Content on or through the Platform, You represent and warrant that:
          <p />  Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms and Conditions, and
          <p />  That the posting of your Content on or through the Platform does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
          <p />  You retain any and all of your rights to any Content you submit, post or display on or through the Platform and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third-party posts on or through the Platform. However, by posting Content using the Platform you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Platform.
          <p />  You agree that this license includes the right for us to make your Content available to other users of the Platform, who may also use your Content subject to these Terms and Conditions.
          <p />  P4U-user has the right but not the obligation to monitor and edit all Content provided by users. In addition, Content found on or through this Platform are the property of P4U-user or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
          <h1>  PROHIBITED USES</h1>
          You may use our Platform only for lawful purposes and in accordance with Terms and Conditions herein.
          <p /> You agree to not use the Platform:
          <p />  In any way that violates any applicable national or international law or regulation.
          <p /> For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
          <p /> To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
          <p /> To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
          <p />  In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
          <p />  To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Platform, or which, as determined by us, may harm or offend Company or users of the Platform or expose them to liability.
          <p />  Additionally, you agree not to:
          <p />  Use the Platform in any manner that could disable, overburden, damage, or impair Platform or interfere with any other party’s use of the Platform, including their ability to engage in real time activities through the Platform.
          <p />  Use any robot, spider, or other automatic device, process, or means to access the Platform for any purpose, including monitoring or copying any of the material on the Platform.
          <p />  Use any manual process to monitor or copy any of the material on Platform or for any other unauthorized purpose without our prior written consent.
          <p />  Use any device, software, or routine that interferes with the proper working of the Platform.
          <p /> Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
          <p /> Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Platform, the server on which the Platform is stored, or any server, computer, or database connected to the Platform.
          <p />Attack the Platform via a denial-of-service attack or a distributed denial-of-service attack.
          <p /> Otherwise attempt to interfere with the proper working of the Platform.
          <h1>ANALYTICS</h1>
          We may use third-party Service Providers to monitor and analyse the use of our Platform. As on date we have not engaged any such service provider.
          <p /><p />
          <h1>ACCOUNTS</h1>
          When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Platform.
          You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Platform or a third-party service. You must notify us immediately upon becoming aware of any peach of security or unauthorized use of your account.
          You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
          We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
          <p /><p />
          <h1>DEVICE PROPERTY</h1>
          We have implemented measures to ensure that our Platform is secure, However, we do not guarantee that our Platform is immune from hacking and virus attacks. You will ensure that you have requisite anti- malware and antivirus software on your mobile device and computer to ensure its safety.
          While we do everything we can to secure your use of the Platform, keep in mind that we cannot contemplate all forms of attack on our Platform. You should, as a matter of practice, ensure that your mobile device and computer are not used wrongly or tampered with in any way.
          <p /><p />
          <h1>CONTENT GUIDELINES</h1>
          We actively remove content which is not allowed on our Platform and violates our Content Guidelines as well as applicable Indian Laws. If such impermissible Content is pought to our attention, we may proceed to take the impermissible content down or ban user Accounts or both. If in the event you come across any content which violated the present Guidelines, we encourage you to report it. We as a Company, emphasize on the importance of freedom of expression, but in no manner whatsoever do we solicit or support Content that intends to cause discomfort, spread what may be under the ambit and purview of hate speech and abuse, promotes violence and illegal activities or impedes the creator or artist ecosystem on the Platform.
          <p />I. Adherence to Applicable Laws
          All content, including without limitation, content that is uploaded, posted, commented on, or shared by you on our Platform, must adhere to the laws of India, including without limitation, the Indian Penal Code,1860 and, the Information Technology Act, 2000 along with all rules and amendments made under such laws. We cooperate with legal authorities and follow enforcement mechanisms in cases of peach of applicable laws. Content may not be uploaded, posted, commented on, or shared by you if it threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order. You may not post or engage with content that is insulting to any other nation, incites the commission of any offences or prevents the investigation of offences.
          <p /> II. Nudity and Pornography
          Content which contains the following is prohibited on the Platform and will be considered as a strict violation of these Guidelines:
          Obscene, sexually explicit, pornographic or nude material or images/videos that expose private parts (sexual organs, female peasts and nipples, buttocks) and/or portray sexual activities;
          Videos or Images of people in compromising positions or content which portrays sexual actions or fetish or erotic intent or sexual arousal;
          Extortion or revenge pornography;
          <h1>Bestiality or zoophilia</h1>
          Content that exploits or endangers any person (for example, listing of phone numbers, or other personal information aimed at any exploitation or endangerment of a person including for the purposes of encouraging or soliciting prostitution or escort services);
          Content that is paedophile or related to child pornography (including without limitation, creation, promotion, glorification, transmission or powsing of child pornography); or
          Content that is indecent, immoral or related to on rape, sexual objectification, non-consensual activities and molestation.
          <p /><p />
          <h1>Harassment or Bullying</h1>
          Content which qualifies as a violation of these Guidelines includes, but is not limited to:
          Posting abusive language or curse words, morphed images, and/or malicious recordings.
          Objectifying, insulting or harassing someone based on their race, caste, colour, disabilities, religion, sexual preferences and /or making sexual advances or otherwise engaging in sexual misconduct will not be tolerated on this Platform. Similarly, extorting or blackmailing any individual otherwise or on the basis of the abovementioned content is strictly prohibited.
          If someone blocks you from their account, kindly do not try and contact them from a different account. If a user does not want to engage with you on the Platform, we urge you to respect the same and vice versa.
          Any image or information of a person that is shared without their consent with an intent to harass, distress or endanger them.
          False information posted to harass someone for financial gain, or cause any injury to them
          However, if a matter involves critical discussion and deliberation of such individuals who are featured in the news or tend to have a large public audience, we may allow it subject to the Terms and these Guidelines.
          <p /><p />
          <h1>Intellectual Property</h1>
          We aim to safeguard intellectual property rights and consider violations of such rights as serious misconduct. All content such as literary, musical, dramatic, artistic, sound recordings, cinematographic works, is subject to intellectual property protection.
          Posting content on the Platform which is not original and is copied from an individual/organisation who owns the intellectual property rights in such content/works is not allowed. Any content which violates the intellectual property rights of third parties will be taken down and strict action will be taken against users who are repeat defaulters. If you wish to reshare such content from within the Platform, kindly do not remove any attributions, watermarks and original captions which state the authentic source of the content. In addition to this, please take the required permissions and give due credits to your fellow users or any other organisation/individual who own the intellectual property rights in such content by mentioning their name and /or the original source.
          <p /><p />
          <h1>Violence</h1>
          Violence includes all content that causes discomfort to our users due to the goriness in the content, such as but not limited to graphical images or videos that glorify violence and suffering, or intends to incite violence, depiction of physical violence or animal cruelty. Content which promotes dangerous and illegal activities, or praises individuals, groups or leaders involved in terrorism, organized violence or criminal activities is strictly prohibited.
          Educative or informative content pertaining to violence may be allowed on the Platform. Violent content on the Platform in the form of fictional set-up, martial arts may be permitted subject to these Guidelines.
          <p /><p />
          <h1>Hate speech and Propaganda</h1>
          Content that promotes violent behaviour against an individual or a group of individuals, intends to intimidate, target or demean any particular religion, race, caste, ethnicity, community, nationality, disability (physical or mental), diseases or gender, is prohibited. Any kind of content which produces hatred or has the intention of creating or spreading hatred or hate propaganda along the lines of including, but not limited to religion, caste, ethnicity, community, sexual orientation or gender identity is also not allowed. We do not entertain content that spreads discrimination, intends to justify violence based on the above-mentioned attributes and refers to an individual or a group of individuals as inferior in any sense or with negative connotations.
          We urge you to refrain from incendiary commentary and publishing theories or hateful ideologies that may cause outrage to our users and influence them negatively. We may permit such content which intends to raise awareness about these issues or challenge it, subject to clear intention of posting such content on the Platform.
          <p /><p />
          <h1>Abuse, Self-Injury or Suicide</h1>
          We do not permit content that exhibits suicide or such tendencies, triggers self-injury and harm or encourages participation in dangerous activities. Posting any content that relates to physical, mental, sexual, or psychological maltreatment, neglect or abuse of any person, be it that of a child or an adult, is strictly condemned. Content showcasing self-harm, glorifying self-injury or suicide or even stating instructions on how to commit self-harm through any means is not allowed. Further, content that identifies, tags, attacks and negatively targets or makes fun of victims and survivors of psychological/physical maltreatment, abuse, self-injury or domestic abuse or any other form of violence is prohibited.
          We allow content that intends to provide support, aid and relief to those undergoing such serious issues. We also allow users to share their experiences which may provide coping mechanisms for those who need help, subject to the intent of posting such content.
          <p /><p />
          <h1>Illegal Activities</h1>
          We have zero-tolerance for content that advocates or promotes illegal activities. We prohibit content related to organized crime, criminal activities, promotion/sale/use of weapons, firearms and explosives, violence or terrorist activities. Sale of illegal goods or services, regulated goods, drugs and controlled substances, and soliciting or selling of sexual services is strictly forbidden.
          We do not permit content that is harassing, harmful, or abusive to children.
          Users may not post content that is related to or encourages money laundering or gambling.
          Users are not allowed to post content that displays tutorials or instructions or educates the users about illegal and prohibited activities including, but not limited to participating in criminal activities, making bombs or encouraging or doing or trading in drugs. Do not use our Platform to solicit or facilitate any transaction or gift involving such goods and services which are declared illegal by the Government of India
          Impersonating another person (such as your family, friends, celepities, pands or any other individuals/organisations) and distributing false or misleading information on our Platform in order to make personal or financial gain will be considered fraud.
          Content that contains computer viruses, malware, or any other computer code designed to restrict the functionality of any computer resource cannot be uploaded on the Platform.
          <p /><p />
          <h1>Non-consensual (Personal) Content</h1>
          Posting or misusing personal content or data or information of another person, including pictures or videos of other people who have not given express consent to such material being posted, is not allowed. Do not post anyone’s personal or intimate photos or videos without their permission or consent. Do not post content that is invasive of anyone’s privacy. We will remove such content.
          Revealing someone’s personal data or sensitive personal information, including without limitation: contact information, address, financial information, Aadhar Number, healthcare information, sexual or intimate images and videos, passport information, or threatening someone to reveal or use such information, will be considered as harassment, and such activities are strictly unacceptable.
          <p /><p />
          <h1>Misinformation</h1>
          We aim to combat the spread of misinformation on our Platform. Any kind of content which spreads deliberate misinformation, disinformation, hoaxes or fake propaganda, with the intent to mislead the users or general public at large, is not permitted. We prohibit posting of content that exaggerates an existing piece of news by introducing non-factual elements into it.
          We do not allow content on the Platform that misleads users or attempts to create an avenue for fapicating content, or is defamatory, libellous, or attempts to damage someone’s reputation, or hurt their financial or political standing on the basis of incorrect information. We engage third party fact checkers to combat the menace of fake news basis which we proactively warn our users that a piece of content is found to be factually incorrect. We urge you to take the same into consideration and act accordingly.
          However, we do not confuse fake news with any satire or parodies. We allow for such content on the Platform provided the content does not mislead other users and the intent behind the same is not to spread false information.
          <p /><p />
          <h1> PLATFORM SECURITY</h1>
          <p /> I. Reporting
          When you see any content or activity that violates these Guidelines, please tap or click on the REPORT button. When you report content, we will process and review the content. If we find the content or activity to be unsuitable for our Platform, we will remove it. If you believe any content on the Platform infringes your rights as a copyright holder, you can file a copyright claim by sending an email to support@pepul.com and the same will be assessed for further review and action. There may be such content on the Platform which you do not like but the same does not violate these Guidelines. In that case, we request you to either unfollow or block such users.
          <p /> II. Intermediary status and review of content
          We are an intermediary as per the applicable laws. We do not control what our user's post, comment, share or say on the Platform and are not responsible for their (or your) actions (whether online or offline). We are not responsible for services and features offered by others even if you access them through our Platform. Our responsibility and liability for anything that happens on our Platform are strictly governed and limited by the laws of India.
          We expect you to be responsible for what you post and what you see. If any of our users report your content to be against these Guidelines, we may take enforcement actions as necessary.
          <p /> III. Grievance officer
          P4U-User l has a Grievance Officer to address your concerns regarding data safety, privacy, and other Platform usage concerns.
          <p />You may contact Email: support@planext4u.com
          <p /> Note : - This email is solely for use by police and investigating agencies. This is not the correct email ID for user-related issues. For all user-related grievances, kindly contact us at support@planext4u.com
          <p />IV. Right to Challenge
          In case the content you upload or post, or your activity is reported by another user and removed from our Platform, we will notify you of such removal and our reasons for the same. If you believe that your content has been unfairly removed, you may write to us at support@planext4u.comto challenge the removal. We may review the content again and determine if it may be reposted on the Platform.
          <p />V. Our actions against violators
          We take strict and prompt action against those who violate these Guidelines. If your profile is reported for violating these Guidelines, then your profile may be suspended temporarily. In case of repeated peach of these Guidelines, we may be compelled to terminate your account with us permanently and block you from registering with us.
          If required, we will cooperate with legal authorities and law enforcement mechanisms. Please note that we are under no obligation to assist you.
          <p /><p />
          <h1>INTELLECTUAL PROPERTY</h1>
          The Platform and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of P4U-User and its licensors. The Platform is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of P4U-User.
          <p /><p />
          <h1>ERROR REPORTING AND FEEDBACK</h1>
          You may provide us either directly at support@planext4u.com. or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Platform ("Feedback"). You acknowledge and agree that:
          you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback;
          Company may have development ideas similar to the Feedback;
          <p />
          Feedback does not contain confidential information or proprietary information from you or any third party; and
          Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
          Our Platform may contain links to third party web sites or services that are not owned or controlled by the Company.
          <p /><p />
          <h1>LINKS TO OTHER WEB SITES</h1>
          The Company has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
          You acknowledge and agree that company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services.
          We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
          <p /><p />
          <h1>DISCLAIMER OF WARRANTY</h1>
          These services and the Platform provided by the Company is on an “as is” and “as available” basis. Company makes no representations or warranties of any kind, express or implied, as to the operation of their services, or the information, content or materials included therein. You expressly agree that your use of these services, their content, and any services or items obtained from us is at your sole risk.
          Neither company nor any person associated with company makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Platform. Without limiting the foregoing, neither company nor anyone associated with company represents or warrants that the Platform, their content, or any services or items obtained through the Platform will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that the Platform or the server that makes it available are free of viruses or other harmful components or that the Platform or any services or items obtained through the Platform will otherwise meet your needs or expectations.
          Company hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose.
          The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.
          <p /><p />
          <h1> LIMITATION OF LIABILITY</h1>
          We do not assume any liability with respect to any loss or damage, arising directly or indirectly due to any inaccuracy or incompleteness of any information or a peach of any warranty or guaranty due to the actions of any user of the Platform
          The Platform and services, are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. We do not warrant the quality of the services or the Platform including its uninterrupted, timely, secure or error-free provision, continued compatibility on any device, or correction of any errors.
          In no event shall we, or any of our affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, service providers, and suppliers be liable for any special, incidental, punitive, direct, indirect or consequential damages suffered as a consequence of a peach of the Terms by another user or arising out of the use of or the reliance on the Platform.
          <p /><p />
          <h1> INDEMNIFICATION</h1>
          <p />  You agree to indemnify, defend and hold harmless us, and our subsidiaries, affiliates and agents and their respective officers, directors, employees, successors and assigns from and against any claim, proceeding, loss, damage, liability, cost, demand or expense (including but not limited to attorney's fees) of any kind arising out of:
          <p /> I. your access to or use of the Platform;
          <p /> I. any peach by you of your obligations under this Agreement;
          <p />III. your violation of the rights of any third party, including any infringement of intellectual property, or of any privacy or consumer protection right;
          <p />IV. any violation of law or contractual obligation and any claims, demands, notices pursuant to such violation;
          <p />V. your negligence or wilful misconduct
          This obligation will survive the termination of our Terms.
          <p /><p />
          <h1>TERMINATION</h1>
          We may terminate or suspend your account and bar access to the Platform immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a peach of the Terms and Conditions.
          If you wish to terminate your account, you may simply discontinue using our Platform. All provisions of the Terms and Conditions which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
          <p /><p />
          <h1>GOVERNING LAW</h1>
          These Terms and Conditions shall be governed and construed in accordance with the laws of India, which governing law applies to agreement without reference to conflict of Law Principles, and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the Courts at Chennai, Tamil Nadu, India. Our failure to enforce any right or provision of these Terms and Conditions will not be considered a waiver of those rights. If any provision of these Terms and Conditions is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms and Conditions constitute the entire agreement between us regarding our Platform and supersede and replace any prior agreements we might have had between us regarding the Platform.
          <p /><p />
          <h1> CHANGES TO SERVICE</h1>
          We reserve the right to withdraw or amend access to our Platform, and any service or material we provide via the Platform, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Platform, or the entire Platform, to users, including registered users.
          <p /><p />
          <h1> AMENDMENTS TO TERMS</h1>
          We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
          Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.
          By continuing to access or use our Platform after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Platform.

          <p /> (a)
          The Services include the provision of the Platform that enables you to arrange and schedule different home-based services with independent third-party service providers of those services (“Service Professionals”). As a part of the Services, UC facilitates the transfer of payments to Service Professionals for the services they render to you and collects payments on behalf of such Service Professionals.



          <p /> (b)
          The services rendered by Service Professionals are referred to as “Pro Services”. The term “Services” does not include the Pro Services. Planext4u does not provide the Pro Services and is not responsible their provision. Service Professionals are solely liable and responsible for the Pro Services that they offer or otherwise provide through the Platform. Planext4u and its affiliates do not employ Service Professionals, nor are Service Professionals agents, contractors, or partners of Planext4u or its affiliates. Service Professionals do not have the ability to bind or represent Planext4u.
          <p />(c)
          The Platform is for your personal and non-commercial use only, unless otherwise agreed upon on in accordance with the terms of a separate agreement. Please note that the Platform is intended for use only within India. You agree that in the event you avail the Services or Pro Services from a legal jurisdiction other than the territory of India, you will be deemed to have accepted the Planext4u terms and conditions applicable to that jurisdiction.
          <p />  (d)
          The Services are made available under various pands owned by or otherwise licensed to UC and its affiliates.
          <p /> (e)
          A key part of the Services is Planext4u’s ability to send you text messages, electronic mails, or WhatsApp messages, including in connection with your bookings, your utilisation of the Services, or as a part of its promotional and marketing strategies. While you may opt out of receiving these text messages by contacting Planext4u at privacy@planext4u.com or through the in-Platform settings , you agree and acknowledge that this may impact Planext4u’s ability to provide the Services (or a part of the Services) to you.
          <p /> (f)
          In certain instances, you may be required to furnish identification proof to avail the Services or the Pro Services, and hereby agree to do so. A failure to comply with this request may result in your inability to use the Services or Pro Services.
          <p />  (i)
          Planext4u may, in its sole discretion, offer promotional codes that may be redeemed for credits, other features or benefits related to the Services, and/or Pro Services, subject to any additional terms that may apply on a promotional code

          <p />(ii)
          You agree that
          <p />(i) you shall use Planext4u Credits in a lawful manner, and only for the purposes specified by such Planext4u Credits, (ii) you shall not duplicate, sell, or transfer the Planext4u Credits in any manner (including by posting such codes on a public forum) unless you have Planext4u  express prior consent to do so, (iii) Planext4u Credits may be disabled by Planext4u at any time for any reason without any liability to you, (iv) Planext4u Credits are not valid for cash, and (v) Planext4u Credits may expire prior to your use.
          <p />  (iii)
          Planext4u may, at its sole discretion, provide only certain users with Planext4u Credits that may result in different amounts charged for the same or similar services obtained by other users.
          <p />  (iv)
          Planext4u reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Planext4u Credits, by you or any other user, if Planext4u reasonably determines or believes that the use or redemption of the Planext4u Credits was in error, fraudulent, illegal, or in violation of the applicable Planext4u Credit terms or these Terms.
          <p /> (a)
          To avail the Services, you will be required to create an account on the Platform (“Account”). For this Account, you may be required to furnish certain details, including but not limited to your phone number. To create an Account, you must be at least 18 years of age.
          <p /> (b)
          You warrant that all information furnished in connection with your Account is and shall remain accurate and true. You agree to promptly update your details on the Platform in the event of any change to or modification of this information.
          <p />(c)

          You are solely responsible for maintaining the security and confidentiality of your Account and agree to immediately notify us of any disclosure or unauthorised use of your Account or any other peach of security with respect to your Account.
          <p />(d)
          You are liable and accountable for all activities that take place through your Account, including activities performed by persons other than you. We shall not be liable for any unauthorised access to your Account.
          <p />(e)
          You agree to receive communications from us regarding (i) requests for payments, (ii) information about us and the Services, (iii) promotional offers and services from us and our third party partners, and (iv) any other matter in relation to the Services.
          <p />(a)
          Our Platform may contain interactive features or services that allow users who have created an account with us to post, upload, publish, display, transmit, or submit comments, reviews, suggestions, feedback, ideas, or other content on or through the Platform (“User Content”).
          <p /> (b)
          As part of the effective provision of the Services and quality control purposes, we may request reviews from you about Service Professionals and you agree and acknowledge that Service Professionals may provide reviews about you to us. You must not knowingly provide false, inaccurate, or misleading information in respect of the reviews. Reviews will be used by us for quality control purposes and to determine whether Customers and Service Professionals are appropriate users of the Platform. If we determine at our sole discretion that you are not an appropriate user, we reserve the right to cancel your registration and remove you from our Platform.
          <p /> (c)
          You grant us a non-exclusive, worldwide, perpetual, irrevocable, transferable, sublicense able, and royalty-free licence to (i) use, publish, display, store, host, transfer, process, communicate, distribute, make available, modify, adapt, translate, and create derivative works of, the User Content, for the functioning of, and in connection with, the Services and (ii) use User Content for the limited purposes of advertising and promoting the Services, or furnishing evidence before a court or authority of competent jurisdiction under applicable laws.
          <p />  (d)
          In connection with these Terms and the licences granted under this clause, you hereby waive any claims arising out of any moral rights or other similar rights relating to the User Content.
          <p /> (e)
          You agree and acknowledge that Planext4u may, without notice to you, remove, or otherwise restrict access to User Content that, in its sole discretion, violates these Terms.
          <p /> (a)
          You agree that we may, in accordance with our Privacy Policy, collect and use your personal data. The Privacy Policy is available at https://www.planext4u.com/privacy-policy and it explains the categories of personal data that we collect or otherwise process about you and the manner in which we process such data.
          <p /> (b)
          In addition to any consent you may provide pursuant to the Privacy Policy, you hereby consent to us sharing your information with our affiliates or other third party service providers. We may use information and data pertaining to your use of the Services for provision of the Services, analytics, trend identification, and purposes of statistics to further enhance the effectiveness and efficiency of our Services, and provision of beneficial schemes, new offers, and for experience enhancement.
          <p />  (c)
          Subject to applicable laws, we may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to you in connection with criminal or civil proceedings. You understand and agree that in such instances we shall have the right to share such data with relevant agencies or bodies.
          <p />(a)
          Orders: The Platform permits you to request various Pro Services at a time of your choosing based on available slots. To make a booking, you should follow the instructions on the Platform and provide necessary information. We use reasonable efforts to enable you to find a Service Professional who is able to provide that service at the requested time. If, in the unlikely event we cannot find a Service Professional for the specific timeslot, we will contact you to find an alternative time.
          <p /> (b)
          Confirmation: Once you place a request we will provide confirmation of the booking via SMS, email or a push notification. Once your booking has been confirmed, you will be required to make the payment in accordance with these Terms or as indicated on the Platform. Once a Service Professional has been identified for the requested Pro Services, you shall receive confirmation in App or via SMS, email or a push notification.
          <p /> (c)
          Cancellations: Bookings that are cancelled before confirmation on the Platform will not be charged. UC’s cancellation policy sets out applicable cancellation fees.
          <p /> (d)
          Substitution: In case of the unavailability of, or cancellation by a selected Service Professional, we will offer you a substitute of the Service Professional from among our registered Service Professionals.
          <p />(a)
          Planext4u reserves the right to charge you for the different Services you may avail and/or for any other facilities you may opt for, from time to time, on or via the Platform.
          <p />(i)

          In respect of Pro Services that you seek to avail through the Platform, you shall be required to pay Service Professionals the amount indicated at the time of booking as well as amounts towards (a) any additional Pro Services you may avail, (b) out of pocket expenses incurred by the Service Professional, and (c) expenses arising out of the purchase of goods required or utilised for the performance of the Pro Service (“Charges”). In addition to the Charges payable to Service Professionals, UC reserves the right to charge you a convenience fee for facilitating the booking and transferring payments to the Service Professional (this fee is referred to as “Fees”). You acknowledge that the final bill you receive may include additional charges, including without limitation, a safety fee, warranty fee,  or Service Professional welfare fee.
          <p /> (ii)
          Planext4u shall notify you of the applicable Charges, Fees, and payment methods at the time of booking. Generally, you may make payments for Pro Services through credit cards, debit cards, net banking, wallets, UPI or cash upon completion of the Pro Service. We have the right to modify and otherwise restrict the modes of payment available to you. You acknowledge that certain payment methods such as cash upon completion may not always be available to you as a payment method. For the avoidance of doubt, in the event you pay through the method of ‘cash upon completion’, you acknowledge that you will be required to pay both Charges and Fees to the Service Professional.
          <p />(iii)
          The Charges and Fees may be payable at the time of making a booking, or upon the completion of the Pro Service, as specified by Planext4u.
          <p /> (iv)
          For the avoidance of doubt, please note that the Charges are payable to Service Professionals, and Planext4u acts as a limited collection agent on behalf of such Service Professionals to collect and transfer amounts due to them.
          <p />(v)
          Taxes: All Charges and Fees are inclusive of applicable taxes.
          <p /> (vi)
          Planext4u reserves the right to reasonably amend the Charges and Fees at any time at its sole discretion. A change in Fees shall not impact any bookings that have been confirmed before the publication of the revised Fees on the Platform.
          <p /> (vii)
          Charges and Fees that you pay are final and non-refundable, unless otherwise determined by Planext4u or required by the applicable laws. Under certain laws, you may be entitled to a refund or other remedies for a failure in the provision of the Services.
          <p />(viii)
          You acknowledge and agree that Charges and Fees applicable in certain geographical areas may increase substantially during times of high demand. Planext4u will use reasonable efforts to inform you of the Charges and Fees that may apply. However, by using the Pro Services or Services, you will be responsible for the Charges and Fees incurred under your Account regardless of your awareness of such Charges or Fees.
          <p />(c)
          Payment Processors: We may use a third-party payment processor (“Payment Processor”) to bill you through your selected mode of payment. The processing of payments will be subject to the terms and policies of such Payment Processor in addition to these Terms. We shall not be liable for any error of the Payment Processor. In the event of any unsuccessful payment, the money debited shall be credited in accordance with the terms of the Payment Processor.
          <p /> (d)
          Cancellation: You may elect to cancel your request for services from a Service Professional at any time prior to such Service Professional’s arrival, in which case you may be charged a cancellation fee in accordance with Planext4u’s cancellation policy. Planext4u reserves the right to charge you, or otherwise deduct applicable taxes in respect of such cancellation fee.
          <p />(e)

          Subscriptions: Planext4u may from time to time offer subscription packages (howsoever named) for monetary consideration. The packages shall provide Customers with additional benefits, which may include the ability to avail discounted Pro Services. You agree that subscription packages (howsoever named) shall be subject to additional terms and conditions. You acknowledge that such subscription packages will be subject to additional terms and conditions that will be deemed to be an integral part of these Terms.
          <p /> (f)
          Planext4u does not designate any portion of your payment as a tip or gratuity to the Service Professional. Any representation by Planext4u to the effect that tipping is “voluntary”, “not required”, and/or “included” in the payments you make for Pro Services is not intended to suggest that Planext4u provides any additional payments to Service Professionals. You understand and agree that while you are free to provide additional payment as a gratuity to any Service Professional who provides you with Pro Services, you are under no obligation to do so. Gratuities are voluntary.
          <p /> (a)
          Planext4u prohibits discrimination against Service Professionals, including on the basis of race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age, or any other characteristic that may be protected under applicable law. Such discrimination includes but is not limited to any refusal to accept Pro Services based on any of these characteristics.
          <p />  (b)
          We request that you treat all Service Professionals with courtesy and respect, and that you provide them with a safe, clean, and appropriate location to perform the Pro Services. Service Professionals shall be entitled to refuse to perform Pro Services if you have not provided a safe, clean, and appropriate location for them, or you behave towards them in a manner which is discourteous, disrespectful, abusive, or otherwise inappropriate. We reserve the right to withhold access to the Services and otherwise limit your access to Pro Services at our absolute discretion if you behave towards any Service Professional in a manner which is discourteous, disrespectful, or abusive, or which we otherwise deem to be inappropriate or unlawful.
          <p /> (c)

          You agree that you will be liable for discriminating against Service Professionals or for any failure, intentional or otherwise, to provide the Service Professionals a safe, clean, and appropriate location for them to perform the Pro Services. Additionally, you will also disclose any and all information that may have a bearing on the ability of the Service Professional to perform the Pro Services or impact the Services Professional’s health, safety, or well-being, to Planext4u and the Service Professional.
          <p /> (d)
          You agree that in the event a Service Professional behaves in a manner that is discourteous, disrespectful, abusive, inappropriate, or in violation of the law, you shall be required to report such incident to support@planext4u.com at the earliest but in any event within 48 (forty eight) hours of such incident.
          <p /> (a)
          The Platform may include services, content, documents, and information owned by, licensed to, or otherwise made available by, a third party (“Third Party Services”) and contain links to Third Party Services. You understand and acknowledge that Third Party Services are the sole responsibility of the third party that created or provided it and that use of such Third Party Services is solely at your own risk.
          <p /> (a)
          You represent and warrant that all information that you provide in relation to the Services and Pro Services is complete, true, and correct on the date of agreeing to these Terms and shall continue to be complete, true, and correct while you avail the Services and/or the Pro Services. Should any information that you provide change during the existence of these Terms, you undertake to immediately ping such change to our notice. We do not accept any responsibility or liability for any loss or damage that you may suffer or incur if any information, documentation, material, or data, provided to avail the Services is incorrect, incomplete, inaccurate, or misleading or if you fail to disclose any material fact.
          <p /> (b)
          You shall extend all cooperation to us in our defence of any proceedings that may be initiated against us due to a peach of your obligations or covenants under these Terms.
          <p />  (c)
          In respect of the User Content, you represent and warrant that:
          <p />(i)
          you own all intellectual property rights (or have obtained all necessary permissions) to provide User Content and to grant the licences under these Terms;
          <p />(ii)
          you are solely responsible for all activities that occur on or through your account on the Platform and all User Content;
          <p />(iii)
          the User Content does not and shall not violate any of your obligations or responsibilities under other agreements;
          <p />(iv)
          the User Content does not and shall not violate, infringe, or misappropriate any intellectual property right or other proprietary right including the right of publicity or privacy of any person or entity;
          <p />(v)

          the User Content does not and shall not contain any viruses, corrupted data, or other harmful, disruptive, or destructive files or content;
          <p />(vi)
          the User Content does not and shall not violate any third party rights; and
          <p />(vii)
          the User Content (A) does not belong to any other person to which you do not have any right, (B) does not threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, public order, cause incitement to the commission of any cognisable offence, prevents investigation of any offence, or is insulting another nation, (C) is not defamatory, grossly harmful, blasphemous, paedophilic, invasive of another’s privacy, discriminatory based on gender, ethnically objectionable, disparaging, relating to, or encouraging money laundering or gambling, libellous, hateful, racist, violent, obscene, pornographic, unlawful, harmful to children, or (D) otherwise offensive, objectionable, or restricts, or inhibits, any other person from using or enjoying the Services.
          <p />(d)
          You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, you shall not:
          <p />(i)
          infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of any party;
          <p />(ii)
          except as may be provided hereunder, copy, display, distribute, modify, publish, reproduce, store, transmit, post, translate, create any derivative works from or license the Services;
          <p />(iii)

          use the Services to transmit any data, or send or upload any material that contains viruses, Trojan horses, worms, time bombs, keystroke loggers, spyware, adware, or any other harmful programmes, or similar computer code, designed to adversely affect the operation of any computer software or hardware;
          <p />(iv)
          use any robot, spider, other automated device, or manual process to monitor or copy the Services or any portion thereof;
          <p />(v)
          engage in the systematic retrieval of content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory;
          <p /> (vi)
          use the Services in (A) any unlawful manner, (B) for fraudulent or malicious activities or (C) in any manner inconsistent with these Terms;
          <p />(vii)
          decompile, reverse engineer, or disassemble the Services;
          <p />(viii)
          link to, mirror, or frame, any portion of all or any of the Services; or
          <p /> (e)
          You warrant that you shall not engage in any activity that interferes with or disrupts the Services.
          <p />(f)

          You shall not attempt to gain unauthorised access to any portion or feature of the Services, any other systems or networks connected to the Services, to any of our servers, or through the Platform by hacking, password mining, or any other illegitimate means.
          <p /> (g)
          You shall not directly or indirectly, in any capacity, solicit, attempt to influence, engage, approach, or accept or encourage the solicitations or approach of, a Service Professional from whom you have availed Pro Services, to either terminate or otherwise cease their registration on or engagement with the Platform, or avail services the same as or similar to the Pro Services independently, without booking the Pro Services through your Account. You agree that this limitation is reasonable and fair and is necessary for the protection of the privacy and security of Service Professionals and that this will not preclude you from obtaining services the same as or similar to the Pro Services through the Platform or other means. You further agree that any potential harm to Service Professionals from the non-enforcement of this clause far outweighs any potential harm to you.
          <p />(a)
          All rights, titles, and interest in, and to the Services, including all intellectual property rights arising out of the Services, are owned by or otherwise licensed to us. Subject to compliance with these Terms, we grant you a non-exclusive, non-transferable, non-sub licensable, revocable, and limited licence to use the Services in accordance with these Terms and our written instructions issued from time to time. Any rights not expressly granted herein are reserved by Planext4u or Planext4u licensors.
          <p /> (b)
          We may request you to submit suggestions and other feedback, including bug reports, relating to the Services from time to time (“Feedback”). We may freely use, copy, disclose, publish, display, distribute, and exploit the Feedback we receive from you without any payment of royalty, acknowledgement, prior consent, or any other form of restriction arising out of your intellectual property rights.
          <p />(c)
          Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in, or licence to, our or any third party’s intellectual property rights.
          <p /> (a)
          These Terms shall remain in effect unless terminated in accordance with the terms hereunder.
          <p />(b)
          We may restrict, deactivate, or terminate your access to, or use of, the Services, or any portion thereof, (i) immediately and at any point at our sole discretion, (A) if you violate or peach any of the obligations, responsibilities, or covenants under these Terms, (B) when you cease to become a user of our Services, (C) you do not, or are likely not to qualify under applicable law, or the standards and policies of UC or its affiliates, to access and use the Services, or (D) violate or peach the Community Guidelines, (ii) upon 30 (Thirty) days’ prior written notice to you, or (iii) immediately for any legitimate business, legal, or regulatory reason.
          <p />(c)
          You may terminate these Terms, at any time, for any reason by sending a notice to Planext4u at privacy@planext4u.com
          <p />(iii)
          these Terms shall terminate, except for those clauses that are expressly, or by implication, intended to survive termination or expiry.
          <p /> (a)
          The Services are provided on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including without limitation the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, we make no warranty that the Services will meet your requirements or expectations.
          <p /> (b)
          No advice or information, whether oral or written, obtained by you from us shall create any warranty that is not expressly stated in the Terms.
          <p />(c)
          While Planext4u strives to provide accurate information about Pro Services and Charges, pricing errors may occur from time to time.
          <p /> (d)
          You agree and acknowledge that we are merely a Platform that connects you with Service Professionals, and we shall not be liable in any manner for any obligations that have not been explicitly stated in these Terms. We are not liable or responsible for fulfilment of any bookings, for the performance of the Pro Services by any Service Professional, or for any acts or omissions of the Service Professionals during their provision of the Pro Services including any damage they may cause to property. By booking Pro Services through the Platform, you are entering into a contract with the relevant Service Provider for the provision of those services, and we accept no responsibility or liability, nor do we make any warranty, representation, or guarantee in respect of the Service Professional’s performance under that contract.
          <p /> (e)
          You agree and acknowledge that soliciting or receiving services from any Service Professional independently is solely at your own risk, and in such an event, you waive any rights that you may have under these Terms.
          <p />(f)
          We do not guarantee or warrant and we make no representation whatsoever regarding the reliability, quality, or suitability of the Service Professionals.
          <p />(g)
          You hereby accept full responsibility for any consequences that may arise from your use of the Services and Pro Services, and expressly agree and acknowledge that we shall have absolutely no liability in this regard.
          <p />(h)

          Planext4u will maintain a complaints management framework and will manage this framework on behalf of Service Professionals in a reasonable manner and in accordance with the non-excludable requirements of relevant applicable laws.
          <p />(i)
          To the fullest extent permissible by law, we, our affiliates, and our related parties, each disclaim all liability for any loss or damage arising out of, or due to:
          <p /> (i)
          your use of, inability to use, or availability or unavailability of the Services or the Pro Services;
          <p />  (ii)
          the occurrence or existence of any defect, interruption, or delays, in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction, or unauthorised access to our records, programmes, services, servers, or other infrastructure relating to the Services;
          <p /> (iii)
          the failure of the Services to remain operational for any period of time; and
          <p />(iv)
          the loss of any User Content and any other data in connection with your use of the Services.
          <p />(j)
          In no event shall Planext4u, its officers, directors, and employees, or its contractors, agents, licensors, partners, or suppliers, be liable to you for any direct, special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation, lost business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever, including but not limited to any abuse or peach of data), even if Planext4u or an authorised representative had been advised of the possibility of such damages, arising out of, or relating to (A) these Terms, (B) the Services or the Pro Services, (C) your use or inability to use the Services or the Pro Services, or (D) any other interactions with another user of the Services.
          <p /> (k)
          To the maximum extent permitted by law, our liability shall be limited to the amount of commission we receive in respect of a particular booking made on the Platform. In no event shall our total liability to you in connection with these Terms exceed INR 10,000 (Rupees Ten Thousand).
          <p /> (l)
          Nothing in these Terms will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit.
          You shall indemnify, defend at our option, and hold us, our parent companies, subsidiaries, affiliates, and our officers, employees, directors, agents, and representatives, harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and costs (including, without limitation, all damages, liabilities, settlements, and attorneys’ fees), due to or arising out of your access to the Services or Pro Services, use of the Services or Pro Services, violation of these Terms, or any violation of these Terms by any third party who may use your Account.
          <p /><p />
          <h1>14. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION</h1>
          <p />(a)
          These Terms shall be governed by and construed and enforced in accordance with the laws of India. Subject to other provisions in this clause, courts in New Delhi shall have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.
          <p />(b)
          Any controversies, conflicts, disputes, or differences, arising out of these Terms shall be resolved by arbitration in New Delhi in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this clause. The tribunal shall consist of 1 (One) arbitrator appointed by UC. The language of the arbitration shall be English. The parties to the arbitration shall keep the arbitration confidential, and not disclose to any person, other than on a need to know basis, or to legal advisors, unless required to do so by law. The decision of the arbitrator shall be final and binding on all the parties thereto. Each party to the arbitration shall bear its own costs with respect to any dispute.
          <p />(a)
          You may contact our designated Grievance Redresses Officer with any complaints or queries relating to the Services or these Terms through registered post or through email, details of which are provided below:
          <p />(b)
          We shall ensure that your complaint is resolved within timelines prescribed by applicable laws.
          <p />(a)
          Changes to Terms: The Terms are subject to revisions at any time, as determined by us, and all changes are effective immediately upon being posted on the Platform. It is your responsibility to review these Terms periodically for any updates or changes. You will be deemed to have accepted the changes made to these Terms if you continue to use the Platform once it has been posted.
          <p />(b)
          Modification to the Services: We reserve the right at any time to add, modify, or discontinue, temporarily or permanently, the Services (or any part thereof), with or without cause. We shall not be liable for any such addition, modification, suspension, or discontinuation of the Services.
          <p />(c)
          Severability: If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue to be in effect. If any unlawful or unenforceable provision would be lawful or enforceable if a part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).
          <p />(d)
          Assignment: You shall not license, sell, transfer, or assign your rights, obligations, or covenants under these Terms, or your Account in any manner without our prior written consent. We may grant or withhold this consent at our sole discretion, subject to any conditions we deem appropriate. We may assign our rights to any of our affiliates, subsidiaries, or parent companies, any successor in interest of any business associated with the Services, or any third party without any prior notice to you.
          <p />(e)
          Notices: All notices, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to legal@urbancompany.com.
          <p />(f)
          Third Party Rights: No third party shall have any rights to enforce any terms contained herein.
          <p />(g)
          Force Majeure: We shall have no liability to you if we are prevented from or delayed in performing our obligations, or from carrying on our business, by acts, events, omissions, or accidents beyond our reasonable control, including without limitation, strikes, failure of a utility service or telecommunications network, act of God, war, riot, civil commotion, malicious damage, or compliance with any law or governmental order, rule, regulation, or direction.
          <p /><p />
          <h1>WAIVER AND SEVERABILITY</h1>
          No waiver by Company of any term or condition set forth in Terms and Conditions shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under the Terms and Conditions shall not constitute a waiver of such right or provision.
          If any provision of the Terms and Conditions is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and effect.
          <p /><p />
          <h1>ACKNOWLEDGEMENT</h1>
          By using the Platform or other services provided by us, you acknowledge that you have read these Terms and Conditions and agree to be bound by them.
          <p /><p />

        </div>
      </div>

    </div>
  );
};
export default TermsAndConditions;