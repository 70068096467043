import React, { useState } from "react";
import Logo from "../Images/planextlogo.png";
import { useNavigate } from "react-router-dom";
import MenuIcon from "../Assets/Menu.png";
import CloseIcon from "../Assets/Close.png";

function Header() {
  const Menus = [
    { name: "Home", _route: "/" },
    { name: "Community", _route: "/Community" },
    { name: "Cultural", _route: "/Cultural" },
    { name: "Our Vision", _route: "/OurVision" },
    { name: "Kalam", _route: "/KalamEvent" },
    { name: "Contact Us", _route: "/Contact" },
  ];
  const navigate = useNavigate();
  const [selected, setSelected] = useState("/");
  const onSelectTheOption = (_route_name) => {
    navigate(_route_name);
    setSelected(_route_name);
  };
  const [open, setOpen] = useState(false);
  window.addEventListener("click", (e) => {
    if (e.target.id !== "menu") {
      setOpen(false);
    }
  });
  return (
    <>
      <header className="flex flex-col h-[100px] z-50 shadow-xl bg-[#131451] text-white  sticky top-0 w-full">
        <nav className="xl:max-w-screen-xl  mx-auto flex flex-row items-center w-full h-full justify-between px-4">
          <div className="xl:w-[20%] lg:w-[20%] w-[50%]">
            <img
              className="w-16 h-16 object-contain"
              src={Logo}
            />
          </div>
          <div className="xl:w-[80%] lg:w-[80%] w-[30%] flex flex-row justify-end h-full xl:gap-6 lg:gap-5">
            {Menus.map((item, index) => <div
              className={selected == item._route ? "ActiveStyle" : "menuStyle"}
              onClick={() => onSelectTheOption(item._route)}
            >
              {item.name}
            </div>)}
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="xl:hidden lg:hidden flex"
          >
            <img
              id="menu"
              className="w-[28px] h-[28px] cursor-default object-contain"
              src={open ? CloseIcon : MenuIcon}
            />
          </div>
        </nav>
      </header>

      {open ? (
        <aside className="w-64 h-screen bg-white duration-300 transform xl:hidden lg:hidden flex flex-col shadow-xl border-2 top-0 fixed left-0 p-5 py-8 z-50">
          <div className="flex flex-col gap-8">
            {Menus.map((menu, i) => (
              <>
                <div
                  className={
                    selected == menu._route
                      ? "text-primary cursor-pointer border-b-2  border-primary"
                      : "text-black cursor-pointer hover:text-primary"
                  }
                  onClick={() => onSelectTheOption(menu._route)}
                >
                  <div onClick={() => onSelectTheOption(menu._route)}>
                    {menu.name}
                  </div>
                </div>

              </>
            ))}
          </div>
        </aside>
      ) : null}
    </>
  );
}

export default Header;
