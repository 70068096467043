import React from "react";
import { useNavigate } from "react-router-dom";
import FooterLogo from "../Images/planextlogo.png";

function Footer() {

  const Help = [

    {
      name: "Privacy Policy",
      route: "/PrivacyPolicy",
    },
    {
      name: "Shipping Policy",
      route: "/ShippingPolicy",
    },
    {
      name: "Information Policy",
      route: "/InformationPolicy",
    },

    {
      name: "Anti Discrimination Policy",
      route: "/AntiDiscriminationPolicy",
    },

    {
      name: "Refund And Cancellation Policy",
      route: "/Refund",
    },
    {
      name: "Terms & Conditions",
      route: "/TermsConditions",
    },

  ];

  const navigate = useNavigate();
  return (
    <>
      <footer className="bg-[#131451] flex flex-col ">
        <div className="col-fluid py-10 items-center justify-center">
          <div className="grid lg:grid-cols-6 grid-cols-1 w-full gap-5">
            <div className="col-span-1 w-full flex flex-col items-center justify-center gap-4 ">
              <img className="w-full h-32 object-contain" src={FooterLogo} />
            </div>

            <div className="lg:col-span-2 w-full flex flex-col gap-3">
              <p className="text-white">
                SF NO 250/2 JJ NAGAR,SITE NO 15, <br />NAGAMANAICKEN PALAYAM ROAD,
                PATTANAM <br />POST COIMBATORE 641016
                Triplicane,<br /> Chennai-600 005
              </p>
              <div className="flex flex-col gap-2">
                <p className="text-white text-lg">
                  Contact Number
                </p>
                <p className="text-white">
                  +91-9787176868
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-white text-lg">
                  Email
                </p>
                <p className="text-white cursor-pointer">
                  planext4uofficial@gmail.com
                </p>
              </div>
            </div>

            <div className="col-span-1 w-full flex flex-col gap-4 ">
              {Help.map((item, i) => (
                <div key={i}>
                  <p onClick={() => navigate(item.route)} className="text-white cursor-pointer">{item.name}</p>
                </div>
              ))}
            </div>

            <div className="col-span-1 w-full flex flex-col gap-4 ">
              <div className="flex flex-col gap-2">
                <p className="text-white text-lg">
                  For Jobs
                </p>
                <p className="text-[#122BB0]">
                  Careers
                </p>
              </div>
              <div className="flex flex-col gap-2">

                <p className="text-white text-lg">
                  For Support
                </p>
                <p className="text-[#122BB0]">
                  Support@planext.com
                </p>
              </div>
            </div>
            <div className="col-span-1 w-full flex flex-col gap-4  ">
              <div className="flex flex-col gap-4 ">
                <div className="flex flex-col gap-2">
                  <p className="text-white text-lg">
                    For Contact
                  </p>
                  <p className="text-[#122BB0]">
                    hello@planext.com

                  </p>
                </div>
                <div className="flex flex-col gap-2">

                  <p className="text-white text-lg">
                    For Press
                  </p>
                  <p className="text-[#122BB0]">
                    press@planext.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        // style={{ backgroundImage: `url(${FoolterIcon})` }}
        className="flex flex-col items-center justify-center bg-no-repeat bg-cover bg-[#104cba] text-white fixed bottom-0 w-full  h-8"
      >
        <marquee>

          PLANEXT4U ALL SOLUTIONS INDIA PRIVATE LIMITED © Copyrights {new Date().getUTCFullYear()}. All Rights Reserved. | Design by ISL
        </marquee>
      </div>
    </>
  );
}

export default Footer;
