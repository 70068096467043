import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./page/Home";
import Community from "./page/Community";
import OurVision from "./page/OurVision";
import Contact from "./page/Contact";
import Kalam from "./page/Kalam";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import InformationPolicy from "./Components/InformationPolicy";
import TermsAndConditions from "./Components/TermsAndConditions";
import RefundAndCancellationPolicy from "./Components/RefundAndCancellationPolicy";
import AntiDiscriminationPolicy from "./Components/AntiDiscriminationPolicy";
import ShippingPolicy from "./Components/ShippingPolicy";


function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Community" element={<Community />} />
          <Route path="/Cultural" element={<Home />} />
          <Route path="/OurVision" element={<OurVision />} />
          <Route path="/KalamEvent" element={<Kalam />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/InformationPolicy" element={<InformationPolicy />} />
          <Route path="/AntiDiscriminationPolicy" element={<AntiDiscriminationPolicy />} />
          <Route path="/Refund" element={<RefundAndCancellationPolicy />} />
          <Route path="/TermsConditions" element={<TermsAndConditions />} />
          <Route path="/ShippingPolicy" element={<ShippingPolicy />} />

        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
