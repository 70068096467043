import React, { useState } from "react";




const InformationPolicy = () => {

  return (
    <div className="w-full bg-[#04103D] text-white">
      <div className="xl:max-w-screen-xl w-full mx-auto p-3">
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5" >Information Policy</h1>
        <h1 className="text-xl text-white font-bold flex flex-col items-center justify-center m-5">Last Updated 04 July 2024</h1>
        <div className="flex flex-col gap-3">
          <p /> The objective of managing information security is to ensure that its core and supporting business operations continue to operate with minimal disruptions.
          The Senior Management is committed to an effective Information Security Management System in accordance with its strategic business objectives.
          “Planext4u commits to protect its information assets from all threats in order to maintain the confidentiality of information, rely upon the integrity of the information, ensure availability of information, comply with legal, regulatory, statutory and contractual obligations and ensure continual improvements towards organization wide Information Security Management System”
          <p />Information Security translates to preservation of the following goals:
          <p />●      Confidentiality: Assurance that Information is accessible only to those authorized to have access.
          <p />●      Integrity: Assurance of the completeness and accuracy of Information and its processing methods; and
          <p />●      Availability: Assurance that authorized user has access to Information and associated assets when required. This is ensured by regular maintenance of hardware, updated and monitored operating systems, redundant critical resources, IS business continuity management, capacity management and other information security measures we take.
          <p />Information Security Objectives
          <p />Planext4u shall:
          <p />1.      Develop and implement an Information Security Management Systems (ISMS) to protect organization’s information and information systems reasonably and effectively from various internal and external threats.
          <p />2.      Commit to comply with regulatory, legal and business requirements.
          <p />3.      Ensure confidentiality, Integrity, and availability of Information Assets.
          <p />4.      Communicate all pertinent security policies to employees and other interested parties as applicable.
          <p />5.      Identify the information assets, to understand their vulnerabilities and the threats that may expose them to risk, through appropriate risk assessment.
          <p />6.      Ensure that identified risks are mitigated through adequate controls as documented in the risk treatment plan.
          <p />7.      Ensure annual information security awareness training of all employees.
          <p />8.      Provide appropriate access controls for protection against unauthorized access adaptability.
          <p />Planext4u Information Security Policy is designed to provide a risk-based framework for protecting Information Assets of Planext4u. It applies to all Planext4u employees, contractors, and vendors.


        </div>
      </div>

    </div>

  );
};
export default InformationPolicy;