import home from "../Images/home.png";
import Planextlogo from "../Images/planextlogo.png";
import Facebook from "../Assets/facebbok.png";
import Instagram from "../Assets/instagram.png";
import Youtube from "../Assets/youtube.png";
import Linkedin from "../Assets/linkedin.png";
import Google from "../Assets/google.png";
import ourvision from "../Assets/ourvision.png";
import ourvision2 from "../Assets/ourvision2.png";

function OurVision() {
  let style = `flex flex-col gap-3 xl:max-w-[600px] w-full h-full relative`;
  const Socialmedia = [
    {
      name: Facebook
    },
    {
      name: Instagram
    },
    {
      name: Youtube
    },
    {
      name: Google
    },
    {
      name: Linkedin
    },
  ]
  // 'techy': '#04103D',
  // 'techynav': '#131451',
  // 'sub': '#104cba',
  return (
    <div className="w-full bg-[#04103D] py-5">
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <img className="w-20 h-20 object-contain" src={Planextlogo} />
            <div className="text-[#104CBA] text-5xl font-bold inline-block">Our Vision
            </div>
            <p className="text-gray-500">
              Our vision is to provide the best service platform that have positive users which enrich and enhance every individual. Our 75th Independence day will make every Indian from dependent to independent in their wealth and will have financial freedom through our app
            </p>
          </div>
          <div className={style}>
            <img src={ourvision} />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16"/>
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <img src={ourvision2} />
          </div>
          <div className={style}>
            <div className="text-[#104CBA] text-xl font-bold inline-block">ABOUT US   </div>
            <div className="text-white text-2xl">
              Stay connected to work with our mobile application
            </div>
            <p className="text-gray-500">Planext4U is a new trendy platform which facilitates Services and Providers including Social media networking platform, including buying and selling platform first time in India, where people share work with each other & create great opportunities, their beautiful life stories & skills in their interests here in social media networking platform including buying and selling programmes.
            </p>
            <div className="flex gap-5">
              {Socialmedia.map((item, index) =>
                <img style={{ width: "40px" }} src={item.name} />)}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default OurVision
  ;
