import home from "../Images/home.png";
import Planextlogo from "../Images/planextlogo.png";
import user2 from "../Images/user2.png";
import user3 from "../Images/user3.png";
import user4 from "../Images/user4.png";
import Shadow from "../Images/shadow.png";
import circleone from "../Images/circleone.webp";
function Home() {
  let style = `flex flex-col gap-3 xl:max-w-[600px] w-full h-full relative`
  return (
    <div className="w-full bg-[#04103D]">
      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <img className="w-20 h-20 object-contain" src={Planextlogo} />
            <div className="text-[#104CBA] lg:text-5xl text-3xl  font-bold inline-block">Ready To Help You</div>

            <div className="text-white lg:text-2xl text-base">
              We are a certified company. We provide you the best Services and
              Providers across the country for you.
            </div>
            <div className="flex">
              <button className="border-2 rounded-full bg-white text-[#04103D] inline-block p-2" href="/">Apply for investments</button>
            </div>
          </div>
          <div className={style}>
            <img src={home} />

          </div>
        </div>
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16"/>

      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">

          <div className={style}>
            <img src={home} />
          </div>
          <div className={style}>
            <img className="w-20 h-20 object-contain" src={Planextlogo} />
            <div className="text-[#104CBA] text-xl font-bold inline-block">Ready To Help You</div>

            <div className="text-white text-2xl">
              Stay connected to work with our mobile application
            </div>
            <p className="text-gray-500">Planext4U is a new trendy platform which facilitates Services and Providers including Social media networking platform, including buying and selling platform first time in India, where people share work with each other & create great opportunities, their beautiful life stories & skills in their interests here in social media networking platform including buying and selling programmes.
            </p>
            <div className="flex">
              <button className="border-2 rounded-full bg-white text-[#04103D] inline-block p-2" href="/">More details</button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#104cba]  h-1 my-16"/>

      <div className="xl:max-w-screen-xl w-full mx-auto  p-3">
        <div className="text-white flex text-3xl flex-col items-center justify-center w-full">  So, What's New? </div>
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <div className="text-white text-2xl font-bold inline-block">The next-gen services providers Apps
            </div>
            <p className="text-gray-500">
              Our App is entirely different from other Apps which benefits Customers at free entry, Local Vendors and Service Providers.
            </p>
          </div>
          <div className={style}>
            <div className="w-[90%] h-[500px] flex flex-col items-center justify-center  object-contain" style={{ backgroundImage: `url(${circleone})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
              <img className="h-[450px] object-contain" src={user2} />
              <img className="w-full h-10" src={Shadow} />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-5">

          <div className={style}>
            <img className="h-[450px] object-contain" src={user3} />
          </div>
          <div className={style}>
            <div className="text-white text-2xl font-bold inline-block">Hire Experts & Get your Job Done

            </div>
            <p className="text-gray-500">
              We are a certified company and we provide the best service providers at an affordable price for you. Every Customers are esteemed and very valuable to us.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center w-full h-full justify-between flex-wrap lg:flex-nowrap gap-5">
          <div className={style}>
            <div className="text-white text-2xl font-bold inline-block">The next-gen Positive Social Platform

            </div>
            <p className="text-gray-500">
              Our aim is to provide the best service platform that have positive users which enrich and enhance every individual. Our 75th Independence day will make every Indian from dependent to independent in their wealth and will have financial freedom through our app
            </p>
          </div>
          <div className={style} >
            <div className="w-[90%] h-[500px] flex flex-col items-center justify-center  object-contain" style={{ backgroundImage: `url(${circleone})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
              <img className="h-[450px] object-contain" src={user4} />
              <img className="w-full h-10" src={Shadow} />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
